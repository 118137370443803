'use client';

import NavBar from './Desktop';
import MobileMenu from './Mobile';

export interface NavBarProps {
  disableNav?: boolean;
}

export default function Nav({ disableNav }: NavBarProps) {
  return (
    <>
      <div className="mx-auto hidden w-full md:flex">
        <NavBar disableNav={disableNav} />
      </div>
      <div className="fixed top-0 z-50 flex h-16 w-full items-center bg-primary py-2 md:hidden">
        <MobileMenu disableNav={disableNav} />
      </div>
    </>
  );
}
