import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import { ReactElement } from 'react';

import { cn } from '@everfund/ui';

import { bgTailwind, iconBorder, sizeTailwind, sizeTextTailwind } from './styled';

interface Props {
  avatarUrl?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  bgColor?: 'blue' | 'heart' | 'lilac';
  bgHex?: string;
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl';
}

const SIZE_MAP = {
  xs: 96,
  sm: 176,
  base: 256,
  lg: 336,
  xl: 416,
};

const Avatar = ({ size, firstName, lastName, avatarUrl, bgColor = 'blue', bgHex }: Props): ReactElement => {
  if (avatarUrl && avatarUrl !== 'https://www.gravatar.com/avatar?d=mp') {
    return (
      <Image
        className={cn('inline-block rounded-full', sizeTailwind(size))}
        alt="user avatar"
        height={SIZE_MAP[size || 'base']}
        width={SIZE_MAP[size || 'base']}
        src={avatarUrl}
      />
    );
  }

  if (!firstName) {
    return (
      <>
        <span
          className={cn(
            'inline-flex items-center justify-center overflow-hidden rounded-full',
            bgTailwind(bgColor),
            sizeTailwind(size),
            iconBorder(bgColor)
          )}
          style={{
            backgroundColor: bgHex,
          }}
        >
          <FontAwesomeIcon icon={faUser} className="mt-2 h-full w-full text-white" />
        </span>
      </>
    );
  }

  const matches = firstName.match(/\b(\w)/g); // ['J','S','O','N']
  const acronym = matches?.join(''); // JSON

  return (
    <span
      className={cn(
        'inline-flex items-center justify-center overflow-hidden rounded-full',
        bgTailwind(bgColor),
        sizeTailwind(size)
      )}
      style={{
        backgroundColor: bgHex,
      }}
    >
      <span className={cn('font-medium leading-none text-white', sizeTextTailwind(size))}>
        {acronym?.toUpperCase()}
        {lastName && lastName.charAt(0).toUpperCase()}
      </span>
    </span>
  );
};

export default Avatar;
