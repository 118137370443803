import useCrisp from '@/hooks/useCrisp';
import { Link } from '@/navigation';
import routes from '@/utils/routes';
import { useState } from 'react';

import { Logo, cn } from '@everfund/ui';

import { NavBarProps } from '..';
import { Accordion, AccordionContent, AccordionItem } from '../Accordian';
import { LiveModeSwitch } from '../LiveModeSwitch';
import { LottieButton } from '../LottieButton';
import { useNavigationMenus } from '../useNavigationMenus';
import UserMenu from './UserMenu';

export default function NavBar({ disableNav }: NavBarProps) {
  const navigationMenu = useNavigationMenus();
  const activePath = navigationMenu.find((item) => item.active);
  const [activeIcon, setActiveIcon] = useState(activePath?.title ?? 'Home');
  const Crisp = useCrisp();
  Crisp?.setAvailabilityTooltip(false);

  return (
    // Static sidebar for desktop
    <aside className="hidden md:fixed md:inset-y-0 md:z-40 md:flex md:w-72 md:flex-col">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4 dark:border-nord-1 dark:bg-nord-2">
        <div className="flex h-16 shrink-0 items-center">
          <Link
            href={routes.home}
            className="flex items-center justify-between rounded-lg focus:outline-none focus:ring focus:ring-white focus:ring-offset-0"
          >
            <Logo className="h-8" type="icon" />
          </Link>
        </div>
        <nav className="flex flex-1 flex-col justify-between gap-y-7">
          <Accordion
            type="single"
            collapsible
            className="-mx-2 space-y-2"
            defaultValue={activeIcon}
            disabled={disableNav}
            aria-disabled={disableNav}
          >
            {navigationMenu.map((menuItem) => {
              if (!menuItem.isVisible) return null;

              return (
                <AccordionItem
                  key={menuItem.title}
                  onClick={() => {
                    !disableNav && setActiveIcon(menuItem.title);
                  }}
                  value={menuItem.title}
                  className="border-b-0"
                >
                  <LottieButton
                    {...{
                      animationData: menuItem.lottieIcon,
                      title: menuItem.title,
                      active: activeIcon === menuItem.title,
                      hasMenu: !!menuItem.menu,
                      to: menuItem.to,
                      disabled: disableNav,
                    }}
                  />
                  <AccordionContent>
                    {menuItem?.menu &&
                      menuItem.menu.map((subItem) =>
                        subItem.isVisible ? (
                          <li key={subItem.title}>
                            <Link
                              href={subItem.to}
                              className={cn(
                                subItem.active ? 'bg-gray-50 dark:bg-nord-3' : 'hover:bg-gray-50 dark:hover:bg-nord-3',
                                'block rounded-md py-2 pr-2 pl-11 text-sm leading-6 text-gray-700 dark:text-primary'
                              )}
                            >
                              {subItem.title}
                            </Link>
                          </li>
                        ) : null
                      )}
                  </AccordionContent>
                </AccordionItem>
              );
            })}
          </Accordion>
          <div className="-mx-2 space-y-4">
            <UserMenu />
            <LiveModeSwitch disabled={disableNav} />
          </div>
        </nav>
      </div>
    </aside>
  );
}
