import { useUser } from '@/context/user';
import useCrisp from '@/hooks/useCrisp';
import useSingleWorkspace from '@/requests/queries/singleWorkspace';
import Avatar from '@/shared/Avatar';
import { useAuth } from '@clerk/nextjs';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslations } from 'next-intl';

import ApperanceMenu from './AppearanceMenu';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuItem,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from './Dropdown';

const UserMenu = () => {
  const { user } = useUser();
  const { data: workspace } = useSingleWorkspace();
  const { signOut } = useAuth();
  const t = useTranslations('layouts.components.UserMenu');
  const Crisp = useCrisp();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="flex w-full items-center justify-between rounded-md p-2 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50 dark:text-primary dark:hover:bg-nord-3">
        <div className="flex items-center gap-x-2">
          <Avatar size="xs" firstName={user?.firstName} lastName={user?.lastName} />
          <span className="sr-only">{t('yourProfile')}</span>
          <div className="flex flex-col text-left">
            <span aria-hidden="true">{`${user?.firstName} ${user?.lastName}`}</span>
            {workspace?.name && (
              <span
                aria-hidden="true"
                className="w-[195px] truncate text-xs font-normal text-gray-400"
              >{`${workspace.name}`}</span>
            )}
          </div>
        </div>
        <FontAwesomeIcon
          icon={faEllipsisVertical}
          className="!h-4  text-gray-700 dark:text-primary"
          aria-hidden="true"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-60 dark:border-nord-2 dark:bg-nord-1">
        <DropdownMenuLabel>{t('myAccount')}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <ApperanceMenu />
        <DropdownMenuGroup>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <span>{t('contactSupport')}</span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem onSelect={() => Crisp?.chat.open()}>
                  <span>{t('chatToSupport')}</span>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <a href="https://help.everfund.com/">
                    <span>{t('helpDesk')}</span>
                  </a>
                </DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem onSelect={() => signOut()}>
          <span>{t('signOut')}</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UserMenu;
