'use client';

import { useOrganizations } from '@/context/organizations';
import { useHasPermission } from '@/hooks/useHasPermission';
import { usePathname } from '@/navigation';
import routes from '@/utils/routes';
import { Route } from 'next';
import { useTranslations } from 'next-intl';
import { useSearchParams } from 'next/navigation';
import { use, useMemo } from 'react';

import { Permission } from '@everfund/types';

import LottieCardJson from './animations/card.json';
import LottieFundraisingJson from './animations/fundraising.json';
import LottieHomeJson from './animations/home.json';
import LottieIntegrationsJson from './animations/integration.json';
import LottieReportsJson from './animations/reports.json';
import LottieSettingsJson from './animations/settings.json';
import LottieSupportersJson from './animations/supporters.json';

export type NavCategory = {
  title: string;
  to?: Route;
  lottieIcon: object;
  active: boolean;
  isVisible: boolean;
  menu?: {
    isVisible?: boolean;
    title: string;
    active: boolean;
    to: Route | Route<any>;
  }[];
};

const visibleCategory = (hasPermission: (permission: Permission) => boolean, permissions: Permission[]) => {
  return permissions.some((permission) => hasPermission(permission));
};

export const useNavigationMenus = () => {
  const pathname = usePathname();
  const params = useSearchParams();
  const { organizations } = useOrganizations();
  const { hasPermission } = useHasPermission();
  const t = useTranslations('layouts.components.NavigationMenu');

  const navigationMenu = useMemo<NavCategory[]>(
    () => [
      {
        title: t('home'),
        to: routes.home,
        lottieIcon: LottieHomeJson,
        active: pathname.split('/').length === 1,
        isVisible: true,
      },
      {
        title: t('donations'),
        lottieIcon: LottieCardJson,
        active: pathname?.split('/')[1] === 'donations',
        isVisible: visibleCategory(hasPermission, ['payments:read']),
        menu: [
          {
            title: t('singleDonations'),
            to: routes.donations.list({ view: 'single', status: 'succeeded' }),
            active: params?.get('view') === 'single',
            isVisible: hasPermission('payments:read'),
          },
          {
            title: t('recurringDonations'),
            to: routes.donations.list({ view: 'recurring', status: 'succeeded' }),
            active: params?.get('view') === 'recurring',
            isVisible: hasPermission('payments:read'),
          },
          {
            title: t('giftAid'),
            to: routes.donations.list({ view: 'giftaid', status: 'unclaimed' }),
            active: params?.get('view') === 'giftaid',
            isVisible:
              (organizations?.records ?? []).some((org) => org.primaryCountry === 'GB') &&
              hasPermission('payments:read'),
          },
          {
            title: t('marketing'),
            to: routes.donations.list({ view: 'marketing', status: 'any' }),
            active: params?.get('view') === 'marketing',
            isVisible: hasPermission('payments:read'),
          },
          {
            title: t('subscriptions'),
            to: routes.donations.subscriptions('active'),
            active: pathname?.split('/')[1] === 'subscriptions',
            isVisible: hasPermission('subscriptions:read'),
          },
          {
            title: t('payouts'),
            to: routes.donations.payouts,
            active: pathname?.split('/')[1] === 'payouts',
            isVisible: hasPermission('payouts:read'),
          },
        ],
      },
      {
        title: t('reports'),
        lottieIcon: LottieReportsJson,
        to: routes.reports.new,
        active: pathname?.split('/')[1] === 'reports',
        isVisible: visibleCategory(hasPermission, [
          'payments:read',
          'transactions:read',
          'campaigns:read',
          'channels:read',
        ]),
      },
      {
        title: t('supporters'),
        lottieIcon: LottieSupportersJson,
        to: routes.supporters,
        active: pathname?.split('/')[1] === 'supporters',
        isVisible: hasPermission('customers:read'),
      },
      {
        title: t('fundraising'),
        lottieIcon: LottieFundraisingJson,
        active: pathname?.split('/')[1] === 'fundraising',
        isVisible: visibleCategory(hasPermission, ['campaigns:read', 'channels:read', 'templates:read', 'goals:read']),
        menu: [
          {
            title: t('campaigns'),
            to: routes.fundraising.campaigns,
            active: pathname?.split('/')[2] === 'campaigns',
            isVisible: hasPermission('campaigns:read'),
          },
          {
            title: t('channels'),
            to: routes.fundraising.channels,
            active: pathname?.split('/')[2] === 'channels',
            isVisible: hasPermission('channels:read'),
          },
          {
            title: t('templates'),
            to: routes.fundraising.templates,
            active: pathname?.split('/')[2] === 'templates' && pathname?.split('/').length == 3,
            isVisible: hasPermission('templates:read'),
          },
          {
            title: t('goals'),
            to: routes.fundraising.goals,
            active: pathname?.split('/')[2] === 'goals',
            isVisible: hasPermission('goals:read'),
          },
        ],
      },
      {
        title: t('integrations'),
        lottieIcon: LottieIntegrationsJson,
        active: pathname?.split('/')[1] === 'integrations',
        isVisible: visibleCategory(hasPermission, ['endpoints:read']),
        menu: [
          {
            title: t('webhooks'),
            to: routes.integrations.webhooks,
            active: pathname?.split('/')[2] === 'webhook',
            isVisible: hasPermission('endpoints:read'),
          },
        ],
      },
      {
        title: t('settings'),
        lottieIcon: LottieSettingsJson,
        active: pathname?.split('/')[1] === 'settings',
        isVisible: visibleCategory(hasPermission, ['organizations:read', 'users:read', 'auditLogs:read']),
        menu: [
          {
            title: t('organizations'),
            to: routes.settings.organizations,
            active: pathname?.split('/')[2] === 'organization',
            isVisible: hasPermission('organizations:read'),
          },
          {
            title: t('teamMembers'),
            to: routes.settings.teamMembers,
            active: pathname?.split('/')[2] === 'team-members',
            isVisible: hasPermission('users:read'),
          },
          {
            title: t('auditLogs'),
            to: routes.settings.auditLogs,
            active: pathname?.split('/')[2] === 'logs',
            isVisible: hasPermission('auditLogs:read'),
          },
        ],
      },
    ],
    [params, pathname, organizations]
  );

  return navigationMenu;
};
