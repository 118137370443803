export const sizeTailwind = (size?: string) => {
  switch (size) {
    case 'xl':
      return `w-24 h-24`;
    case 'lg':
      return `w-20 h-20`;
    case 'md':
      return `w-12 h-12`;
    case 'sm':
      return `w-10 h-10`;
    case 'xs':
      return `w-8 h-8`;
    default:
      return `h-14 w-14`;
  }
};

export const sizeTextTailwind = (size?: string) => {
  switch (size) {
    case 'xl':
      return `text-4xl`;
    case 'lg':
      return `text-2xl`;
    case 'base':
      return `text-base`;
    case 'xs':
      return `text-sm`;
    default:
      return `text-base`;
  }
};

export const bgTailwind = (color?: string) => {
  switch (color) {
    case 'blue':
      return `bg-blue-500`;
    case 'heart':
      return `bg-green-500`;
    case 'lilac':
      return `bg-lilac-500`;
    default:
  }
};

export const iconBorder = (color?: 'blue' | 'heart' | 'lilac') => {
  switch (color) {
    case 'heart':
      return `border-2 border-green-500`;
    case 'lilac':
      return `border-2 border-lilac-500`;
    case 'blue':
    default:
      return `border-2 border-blue-500`;
  }
};
