'use client';

import { Link } from '@/navigation';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { Route } from 'next';
import { useEffect, useRef } from 'react';

import { cn } from '@everfund/ui';

import { AccordionTrigger } from './Accordian';

interface LottieButtonProps {
  title: string;
  active: boolean;
  open?: boolean;
  animationData: any;
  hasMenu?: boolean;
  to?: Route;
  disabled?: boolean;
}

export const LottieButton = ({ title, active, to, hasMenu, animationData, disabled }: LottieButtonProps) => {
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleMouseEnter = () => {
      lottieRef.current?.play();
    };

    const handleMouseLeave = () => {
      lottieRef.current?.stop();
    };

    anchorRef.current?.addEventListener('mouseenter', handleMouseEnter);
    anchorRef.current?.addEventListener('mouseleave', handleMouseLeave);
    buttonRef.current?.addEventListener('mouseenter', handleMouseEnter);
    buttonRef.current?.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      anchorRef.current?.removeEventListener('mouseenter', handleMouseEnter);
      anchorRef.current?.removeEventListener('mouseleave', handleMouseLeave);
      buttonRef.current?.removeEventListener('mouseenter', handleMouseEnter);
      buttonRef.current?.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  if (!hasMenu && to) {
    return (
      <Link
        href={disabled ? {} : to}
        ref={anchorRef}
        className={cn(
          active ? 'bg-gray-50 dark:bg-nord-3' : 'hover:bg-gray-50 dark:hover:bg-nord-3',
          'hover:bg-gray-50 w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700 dark:text-primary',
          'flex flex-1 items-center justify-between font-medium transition-all [&[data-state=open]>svg]:rotate-180 ',
          disabled && !active && 'opacity-50 cursor-not-allowed '
        )}
        aria-disabled={disabled}
        tabIndex={disabled ? -1 : undefined}
      >
        <div className="flex items-center gap-x-3">
          <Lottie
            lottieRef={lottieRef}
            animationData={animationData}
            autoplay={false}
            className={cn('w-6 h-6 lottie-icon', active && 'active')}
          />
          {title}
        </div>
      </Link>
    );
  }

  return (
    <AccordionTrigger
      ref={buttonRef}
      className={cn(
        active ? 'bg-gray-50 dark:bg-nord-3' : 'hover:bg-gray-50 dark:hover:bg-nord-3',
        'hover:bg-gray-50 w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700 dark:text-primary',
        disabled && !active && 'opacity-50 cursor-not-allowed '
      )}
    >
      <div className="flex items-center gap-x-3">
        <Lottie
          lottieRef={lottieRef}
          animationData={animationData}
          autoplay={false}
          className={cn('w-6 h-6 lottie-icon', active && 'active')}
        />
        {title}
      </div>
    </AccordionTrigger>
  );
};
