import { useUser } from '@/context/user';
import { Link } from '@/navigation';
import Avatar from '@/shared/Avatar';
import { useAuth } from '@clerk/nextjs';
import { faBars, faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Transition } from '@headlessui/react';
import { useTranslations } from 'next-intl';
import { Fragment } from 'react';

import { cn, Logo } from '@everfund/ui';

import { NavBarProps } from '..';
import { Accordion, AccordionContent, AccordionItem } from '../Accordian';
import { LiveModeSwitch } from '../LiveModeSwitch';
import { LottieButton } from '../LottieButton';
import { useNavigationMenus } from '../useNavigationMenus';

const MobileMenu = ({ disableNav }: NavBarProps) => {
  const navigationMenu = useNavigationMenus();
  const { user } = useUser();
  const { signOut } = useAuth();
  const t = useTranslations('layouts.components.Mobile');

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button className="ml-2 inline-flex items-center justify-center rounded-md bg-transparent p-2 hover:bg-white/10 hover:text-white focus:outline-none focus:ring-2 focus:ring-white md:hidden">
            <span className="sr-only">{t('openMenu')}</span>
            <FontAwesomeIcon icon={faBars} className="block size-6 text-black dark:text-white" aria-hidden="true" />
          </Popover.Button>

          <Transition.Root show={open} as={Fragment}>
            <div className="lg:hidden">
              <Transition.Child
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="duration-150 ease-in"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Popover.Overlay static className="fixed inset-0 z-20 bg-black/25" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-150 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  static
                  className="absolute inset-x-0 top-0 z-30 mx-auto w-full max-w-3xl origin-top p-2"
                >
                  <div className="divide-y divide-gray-200 rounded-lg bg-white shadow-lg ring-1 ring-black/5 dark:divide-nord-1 dark:bg-nord-2">
                    <div className="px-2 pb-2 pt-6">
                      <div className="flex items-center justify-between px-4">
                        <div>
                          <Logo type="icon" className="h-8 w-auto align-baseline text-growth" />
                        </div>
                        <div className="-mr-2">
                          <Popover.Button className="inline-flex items-center justify-center rounded-md p-1.5 text-nord-1 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500 dark:text-primary">
                            <span className="sr-only">{t('closeMenu')}</span>
                            <FontAwesomeIcon icon={faX} className="block w-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                      <nav className="mt-4 flex flex-1 flex-col px-2">
                        <ul role="list" className="flex flex-1 flex-col justify-between gap-y-7">
                          <li>
                            <Accordion
                              type="single"
                              collapsible
                              className="-mx-2 space-y-2"
                              defaultValue={'Home'}
                              disabled={disableNav}
                              aria-disabled={disableNav}
                            >
                              {navigationMenu.map((menuItem) => {
                                return (
                                  <AccordionItem key={menuItem.title} value={menuItem.title} className="border-b-0">
                                    <LottieButton
                                      {...{
                                        animationData: menuItem.lottieIcon,
                                        title: menuItem.title,
                                        active: menuItem.active,
                                        hasMenu: !!menuItem.menu,
                                        to: menuItem.to,
                                        disabled: disableNav,
                                      }}
                                    />
                                    <AccordionContent>
                                      {menuItem?.menu &&
                                        menuItem.menu.map((subItem) =>
                                          !subItem.isVisible ? null : (
                                            <li key={subItem.title}>
                                              <Link
                                                href={subItem.to}
                                                className={cn(
                                                  subItem.active
                                                    ? 'bg-gray-50 dark:bg-nord-3'
                                                    : 'hover:bg-gray-50 dark:hover:bg-nord-3',
                                                  'block rounded-md py-2 pr-2 pl-11 text-sm leading-6 text-gray-700 dark:text-primary'
                                                )}
                                              >
                                                {subItem.title}
                                              </Link>
                                            </li>
                                          )
                                        )}
                                    </AccordionContent>
                                  </AccordionItem>
                                );
                              })}
                            </Accordion>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div className="pb-2 pt-4">
                      <div className="flex items-center px-5">
                        <div className="sentry-mask shrink-0">
                          <Avatar size="sm" firstName={user?.firstName} />
                        </div>
                        <div className="ml-3 min-w-0 flex-1 ">
                          <div className="sentry-mask truncate text-base font-medium text-primary">
                            <p>{`${user?.firstName} ${user?.lastName}`}</p>
                            <p className="text-sm font-medium text-secondary">{user?.email}</p>
                          </div>
                        </div>
                      </div>

                      <div className="mt-3 px-4 pb-2 font-medium">
                        <div className="-m-2">
                          <LiveModeSwitch disabled={disableNav} />
                        </div>
                        <button
                          onClick={() => signOut()}
                          className="block w-full rounded-md text-left text-base font-medium text-secondary hover:bg-gray-100 hover:text-primary hover:dark:bg-nord-2"
                        >
                          {t('signOut')}
                        </button>
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition.Child>
            </div>
          </Transition.Root>
        </>
      )}
    </Popover>
  );
};

export default MobileMenu;
